var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid md:flex" },
    [
      _c(
        "div",
        {
          class: "w-full sm:w-full " + "" + (_vm.sendButton ? "md:w-8/12" : ""),
        },
        [
          _c("vue-tags-input", {
            staticClass: "overflow-auto w-full",
            attrs: {
              tags: _vm.tags,
              "autocomplete-items": _vm.autocompleteItems,
              placeholder: _vm.placeholderText,
              separators: [";", " ", "\n", ","],
              "add-only-from-autocomplete": false,
              validation: _vm.validation,
            },
            on: {
              "update:tags": function ($event) {
                _vm.tags = $event
              },
              "tags-changed": _vm.tagsChanged,
            },
            model: {
              value: _vm.tag,
              callback: function ($$v) {
                _vm.tag = $$v
              },
              expression: "tag",
            },
          }),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tags.length > 0 && !_vm.tagsValid,
                expression: "(tags.length > 0 && !tagsValid)",
              },
            ],
            staticClass: "text-danger text-sm",
            attrs: { hidden: !(_vm.tags.length > 0 && !_vm.tagsValid) },
          }),
        ],
        1
      ),
      _vm.sendButton
        ? _c(
            "div",
            {
              staticClass:
                "w-full sm:w-full md:w-4/12 flex justify-end sm:justify-end md:justify-start ml-0 md:ml-4 mt-2 md:mt-0",
            },
            [
              _c("div", { staticClass: "relative w-full" }, [
                _c("div", { staticClass: "flex w-full justify-end" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            type: "filled",
                            disabled: _vm.tags.length === 0 || !_vm.tagsValid,
                          },
                          on: { click: _vm.handleSend },
                        },
                        [_vm._v(_vm._s(_vm.sendText))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            type: "border",
                            "icon-pack": "feather",
                            icon: "icon-file-plus",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showUserImportPopup = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("importar")))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "vs-popup",
        {
          staticStyle: { "overflow-y": "auto", "z-index": "54000" },
          attrs: { title: _vm.popupTitle, active: _vm.showUserImportPopup },
          on: {
            "update:active": function ($event) {
              _vm.showUserImportPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap items-center" },
            [
              _c("ImportExcel", {
                attrs: {
                  exampleUrl: _vm.importUrl,
                  onSuccess: _vm.onSuccess,
                  templateFile: _vm.importFileName,
                  templateMessage: _vm.$t("clique-aqui-baixar-modelo"),
                  accept: ".xlsx, .xls",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

<template>
  <div class="grid md:flex">
    <div :class="'w-full sm:w-full ' + `${ sendButton ? 'md:w-8/12' : '' }`">
      <vue-tags-input
      v-model="tag"
      :tags.sync="tags"
      :autocomplete-items="autocompleteItems"
      :placeholder="placeholderText"
      :separators="[';', ' ', '\n', ',']"
      :add-only-from-autocomplete="false"
      @tags-changed="tagsChanged"
      :validation="validation"
      class="overflow-auto w-full"
      />
      <span
      class="text-danger text-sm"
      :hidden="!(tags.length > 0 && !tagsValid)"
      v-show="(tags.length > 0 && !tagsValid)"
      >
      </span>
    </div>
    <div v-if="sendButton" class="w-full sm:w-full md:w-4/12 flex justify-end sm:justify-end md:justify-start ml-0 md:ml-4 mt-2 md:mt-0">
      <div class="relative w-full">
        <div class="flex w-full justify-end">
          <div >
            <vs-button
              type="filled"
              @click="handleSend"
              class="ml-4"
              :disabled="tags.length === 0 || !tagsValid"
            >{{sendText}}</vs-button>
          </div>
          <div >
            <vs-button
              type="border"
              icon-pack="feather"
              icon="icon-file-plus"
              class="ml-2"
              @click="showUserImportPopup = true"
            >{{ $t('importar') }}</vs-button>
          </div>
        </div>
      </div>
    </div>

    <vs-popup
      :title="popupTitle"
      :active.sync="showUserImportPopup"
      style="overflow-y: auto; z-index: 54000;"
    >
      <div class="flex flex-wrap items-center">
        <ImportExcel 
          :exampleUrl="importUrl"
          :onSuccess="onSuccess"
          :templateFile="importFileName"
          :templateMessage="$t('clique-aqui-baixar-modelo')"
          accept=".xlsx, .xls">
        </ImportExcel>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'
import ImportExcel from '@/components/excel/ImportExcel'
import { before } from 'lodash'

export default {
  props: {
    genericProp: {
      type: Boolean,
      default: false
    },
    placeholderText: {
      type: String,
      default: function () {
        return this.$t('adicionar-e-mails')
      }
    },
    sendText: {
      type: String,
      default: function () {
        return this.$t('adicionar')
      }
    },
    validationText: {
      type: String,
      default: function () {
        return this.$t('adicione-somente-e-mails-validos')
      }
    },
    popupTitle: {
      type: String,
      default: function () {
        return this.$t('importar-usuarios')
      }
    },
    sendButton: {
      type: Boolean,
      default: true
    },
    sendButtonHandle: {
      type: Function,
      default: null
    },
    autocompleteItems: {
      type: Array,
      default: () => { return [] }
    },
    validation: {
      type: Array,
      default() {
        return [
          {
            classes: 'valid-email-or-user',
            rule: ({ text }) => {
              return !this.$utils.validation.email(text)
            }
          }
        ]
      }
    },
    importUrl: {
      type: String,
      default: `${process.env.VUE_APP_API_BASE_URL}/storage/templates/educatena-modelo-importacao-participantes.xlsx`
    },
    importFileName: {
      type: String,
      default: 'educatena-modelo-importacao-participantes.xlsx'
    },
    clearOnSend: {
      type: Boolean,
      default: false
    },
    initTags: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  components: {
    VueTagsInput,
    ImportExcel
  },

  watch: {
    tag(value) {
      this.$emit('tagInput', value)
    }
  },

  data () {
    return {
      tags: [],
      tag: '',
      tagsValid: false,
      showUserImportPopup: false,
    }
  },

  computed: {
  },

  methods: {
    validateTags() {
      for (let index = 0; index < this.tags.length; index++) {
        const tag = this.tags[index]
        for (let index = 0; index < tag.tiClasses.length; index++) {
          const element = tag.tiClasses[index]
          if (element === 'ti-invalid') {
            this.tagsValid = false
            return
          }
        }
      }
      this.tagsValid = true
    },
    colorizeValidTags() {
      this.tags.forEach((tag, index) => {
        if (tag.tiClasses.find(element => element === 'ti-valid')) {
          this.tags[index].classes = 'bg-success'
        }
      })
    },
    tagsChanged(newTags) {
      this.autocompleteItems = []
      this.tags = newTags
      this.validateTags()
      this.colorizeValidTags()
      this.$emit('tagsChanged', this.tags, this.tagsValid)
    },
    handleSend() {
      if (this.sendButtonHandle === null) {
        this.$emit('tagsSent', this.tags)
      } else {
        this.sendButtonHandle(this.tags)
      }

      if (this.clearOnSend) {
        this.clear()
      }
    },
    clear() {
      this.tags = []
    },
    onSuccess(data) {
      if (data && data.results && data.results.length > 0) {
        this.$emit('importStudents', data.results)
      } else {
        this.notifyWarning(this.$vs, this.$t('nenhum-usuario-encontrado-para-importar'))
      }

      this.showUserImportPopup = false
    },
  },

  beforeMount() {
    this.tags.push(...this.initTags)
  }

}
</script>

<style scoped>
  .vue-tags-input {
    max-width: 100% !important;
  }

</style>
